import gql from "graphql-tag"

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($department: String!){
    properties(limit:8, where:{department:$department, publish:true}) {
      id
      crm_id
      status
      search_type
      department
      virtual_tour
      bedroom
      slug
      bathroom
      building
      address
      title
      price
      price_qualifier
      display_address
      description
      long_description
      crm_negotiator_id
      latitude
      longitude
      floorplan
      images
      imagetransforms
    }
}`


/* ========== All News ======== */

const AllNews = gql`
  query GetNews {
    blogs(where:{publish:true}) {
        title
        id
        slug
        tile_image {
          url
          alternativeText
        }
        video_url
        short_description
        post_on
        category
        publish
        imagetransforms
    }
  }
`

/* ========== Other News ======== */
const OtherNews = gql`
  query OtherNews($id: ID!) {
    blogs(sort: "News_Date:desc", limit: 4, where: { id_ne: $id }) {
      id
      title
      tile_image {
        url
        alternativeText
      }
      slug
      post_on
      imagetransforms
    }
  }
`


/* ========== Sales Properties ======== */
const SalesProperties = gql`
  query SaleProperties {
    propertiesConnection(where: {search_type:"sales",publish:true}) {
      aggregate {
        count
      }
    }
  }
`

/* ========== Lettings Properties ======== */
const LettingsProperties = gql`
  query LetProperties {
    propertiesConnection(where: {search_type:"lettings",publish:true}) {
      aggregate {
        count
      }
    }
  }
`

const PeopleEmail = gql`
  query GetPeopleEmail($email_id: String!) {
    teams(where: { email: $email_id }) {
      name
      email
      mobile_no
      designation
      id
      slug
      image {
        url
        alternativeText
      }
      imagetransforms
    }
  }
`


export const Queries = {
    NoResultProperties,
    AllNews,
    OtherNews,
    SalesProperties,
    LettingsProperties,
    PeopleEmail
}  
import { useQuery } from "@apollo/client"
import { Queries } from "./common_queries"

export const NoResultProperties = (status, department) => {
  return useQuery(Queries.NoResultProperties, {
      variables: {status: status, department: department}
  });
}

export const AllNews = () => {
    return useQuery(Queries.AllNews)
}

export const OtherNews = id => {
    return useQuery(Queries.OtherNews, {
      variables: { id: id },
    })
}

export const SalesProperty = () => {
  return useQuery(Queries.SalesProperties)
}

export const LettingsProperty = () => {
    return useQuery(Queries.LettingsProperties)
}

export const PeopleEmail = (email_id) => {
  return useQuery(Queries.PeopleEmail, {
    variables: { email_id: email_id },
  })
}